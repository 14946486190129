
import React, { useEffect,useState,useCallback,useRef ,useLayoutEffect}  from "react";
import axios from 'axios';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

import Iframe from 'react-iframe'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import ListItemIcon from '@mui/material/ListItemIcon';

import VerifiedIcon from '@mui/icons-material/Verified';

import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import TranslateIcon from '@mui/icons-material/Translate';

import QuizIcon from '@mui/icons-material/Quiz';

import KeyboardIcon from '@mui/icons-material/Keyboard';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircularProgress from '@mui/material/CircularProgress';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LessonsList from './LessonsList';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';

function CourseModule (props) {
  const auth=props.auth;

  const [data,setData]= useState('');
  const { course_path,modules_id,lesson_id } = useParams();
  const navigate=useNavigate();
  //http://127.0.0.1:8000/

 const ref = useRef(null)
 const [divWidth, setDivWidth] = useState('')
 const handleResize = () => {
     setDivWidth(ref.current.offsetWidth)
 }

 useEffect(() => {
     if (ref.current) window.addEventListener('resize',
        handleResize)

     return () => {
         window.removeEventListener('resize', handleResize)
     }
 }, [ref])

 useLayoutEffect(() => {
     setDivWidth(ref.current.offsetWidth)
 }, [])


  useEffect ( () =>  {
    axios.post('/api/courses/c001/module/m001/lesson/l001')
        .then(response => {
          console.log(response);
          setData(response)
          //localStorage.setItem('auth',response.data.auth)
        })
        .catch(error => {
           console.error('There was an error!', error);
        });

  },[])
  // if( auth.authorize !=true){
  //   //{this.props.match.params.id}
  //   navigate('/courses/'+course_path)
  //       window.location.reload(true);
  // }
  return (

     <Box sx={{ display: 'flex', height: 60,flexDirection: 'row' }}>
      <Grid container size={{ xs: 12, xl: 3, lg: 4 }}>
      <Button variant="outlined" href="/courses/learning-linux-basics-course-labs"      startIcon={<KeyboardBackspaceIcon />} >
      Delete
      </Button>
      <p>{JSON.stringify({'rez':auth})}</p>
    {window.location.pathname}
    {divWidth}
    <Card sx={{ maxWidth: 345}} >
    {data  &&  <LessonsList auth={auth} lessons={data.data.lessons} />    }
    </Card>
    </Grid>
        <Grid  ref={ref} container size={{xs:12, xl:9 ,lg:8 }}>
        <Iframe  url="http://149.154.70.9:8888/"
             position="absolute"

               width = {divWidth}
             id="myId"
             className="myClassname"
             height="80%"
             >
          </Iframe>
        </Grid>

        </Box>
      )}
export default CourseModule;
  //{JSON.stringify({'data':data})}
//<Route path="/profile/:userid([0-9]{10})" component={Profile} />
