import React, { useState,useEffect }  from "react";
import axios from 'axios';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Iframe from 'react-iframe'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Scenarios () {

  const [data,setData]= useState('');
  //http://127.0.0.1:8000/
  useEffect ( () =>  {
    axios.get('/scenarios')
        .then(response => {
          console.log(response);
          setData(response)
          //localStorage.setItem('auth',response.data.auth)
        })
        .catch(error => {
           console.error('There was an error!', error);
        });

  },[])

  return (

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
 {data &&<Card sx={{ maxWidth: 345}} >
 <CardHeader
 title={data.title }
  sx={{  bgcolor: 'text.primary', color: 'white'}}
/>

{ data.title }

 </Card> }
        </Grid>
        <Grid item xs={9}>
        <Iframe url="http://localhost:8888/"
             position="absolute"
             width="100%"
             id="myId"
             className="myClassname"
             height="100%"
             styles={{height: "250px"}}/>
        </Grid>

      </Grid>
    </Box>



)}
export default Scenarios;
  //{JSON.stringify({'data':data})}
