import React, { useState,useEffect }  from "react";
import axios from 'axios';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

import Iframe from 'react-iframe'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircularProgress from '@mui/material/CircularProgress';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import MobileStepper from '@mui/material/MobileStepper';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, grey } from '@mui/material/colors';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';


import ListItemIcon from '@mui/material/ListItemIcon';

import VerifiedIcon from '@mui/icons-material/Verified';

import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import TranslateIcon from '@mui/icons-material/Translate';

import QuizIcon from '@mui/icons-material/Quiz';

import KeyboardIcon from '@mui/icons-material/Keyboard';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemButton from '@mui/material/ListItemButton';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

function LessonIcon(props) {
  const lesson_type = props.lesson_type;
  if (lesson_type=='video') {    return <PlayCircleOutlineIcon />;  }
  if (lesson_type=='text') {    return <ArticleOutlinedIcon />;  }
  if (lesson_type=='lab') {    return <LaptopMacIcon />;  }
  if (lesson_type=='quiz') {    return <QuizIcon />;  }
  if (lesson_type=='coding') {    return <KeyboardIcon />;  }
}


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const color = purple['A700'];
const color2 =grey['A400']

const DemoPaper = styled(Paper)(({ theme }) => ({

  height: 120,

  marqin: 10,
  backgroundColor: color,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));


const theme = createTheme({

  palette: {
    action: {
      disabledBackground: 'rgb(0 0 0/4%)',
      disabled: 'rgb(0 0 0/40%)'
    }
  }
});
const theme2 = createTheme({
  palette: {
    background: {
      paper: 'rgb(0 0 0/10%',
    },
    text: {
      primary: 'rgb(0 0 0/40%)',

    },

  },
});


function LessonsList (props) {

    const lessons = props.lessons;
    const auth = props.auth;

  // const [data,setData]= useState('');
  // //http://127.0.0.1:8000/
  // useEffect ( () =>  {
  //   axios.post('/api/course/c001')
  //       .then(response => {
  //         console.log(response);
  //         setData(response)
  //         //localStorage.setItem('auth',response.data.auth)
  //       })
  //       .catch(error => {
  //          console.error('There was an error!', error);
  //       });
  //
  // },[])

  return (
  <List >
{ lessons.map((item) => (
    <ListItem
   disablePadding
  secondaryAction={
                     <IconButton disabled={! auth.authorize} edge="end" aria-label="delete">
                      <RadioButtonUncheckedIcon />
                     </IconButton>
                   }
  >
  <ListItemButton disabled={! auth.authorize} dense >
   <ListItemIcon>
      <LessonIcon lesson_type={item.type} />

   </ListItemIcon>
   <ListItemText
     primary={item.name}

   />
   </ListItemButton>

    </ListItem> ))}
        </List>






)}
export default LessonsList;
  //{JSON.stringify({'data':data})}
