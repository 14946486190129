import React, { useState,useEffect }  from "react";
import axios from 'axios';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

import Iframe from 'react-iframe'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircularProgress from '@mui/material/CircularProgress';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import MobileStepper from '@mui/material/MobileStepper';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, grey } from '@mui/material/colors';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';


import ListItemIcon from '@mui/material/ListItemIcon';

import VerifiedIcon from '@mui/icons-material/Verified';

import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import TranslateIcon from '@mui/icons-material/Translate';

import QuizIcon from '@mui/icons-material/Quiz';

import KeyboardIcon from '@mui/icons-material/Keyboard';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemButton from '@mui/material/ListItemButton';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LessonsList from './LessonsList'
import LockIcon from '@mui/icons-material/Lock';




function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const color = purple['A700'];
const color2 =grey['A400']

const DemoPaper = styled(Paper)(({ theme }) => ({

  height: 120,

  marqin: 10,
  backgroundColor: color,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));


const theme = createTheme({

  palette: {
    action: {
      disabledBackground: 'rgb(0 0 0/4%)',
      disabled: 'rgb(0 0 0/40%)'
    }
  }
});
const theme2 = createTheme({
  palette: {
    background: {
      paper: 'rgb(0 0 0/10%',
    },
    text: {
      primary: 'rgb(0 0 0/40%)',

    },

  },
});


function CoursesDetailed (props) {

  const auth=props.auth;

  const [data,setData]= useState('');
  //http://127.0.0.1:8000/
  useEffect ( () =>  {
    axios.post('/api/courses/c001')
        .then(response => {
          console.log(response);
          setData(response)
          //localStorage.setItem('auth',response.data.auth)
        })
        .catch(error => {
           console.error('There was an error!', error);
        });

  },[])

  return (<Grid container>
    <Grid  container size={9}>


 <Card sx={{ background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(8,34,137,1) 43%, rgba(7,65,158,1) 53%, rgba(0,212,255,1) 100%);'
     ,width: 600}}>
 <Stack direction="row" spacing={1}>
   <Chip sx={{color: "white" }} label="Linux" />
   <Chip sx={{color: "white"}} label="DevOps" />
 </Stack>

 <CardHeader sx={{color: "white", }}  title="Learning Linux Basics Course & Labs" />




 <CardMedia
 sx={{ width: 300,p:1 ,m:1 }}
    component="img"

    image="/media/media02.png"
    alt="Paella dish"
  />
<p>{JSON.stringify({'rez':auth})} </p>




<List >
  <ListItem alignItems="flex-start">
    <ListItemAvatar>
      <Avatar alt="Remy Sharp" src="/media/my.jpg" />
    </ListItemAvatar>
    <ListItemText sx={{color: "white", }}
      primary="Алексей Шило"
      secondary={
            <React.Fragment>
              <Typography
                component="span"
              VideoLibraryIcon  variant="body2"
                sx={{ color: 'white', display: 'inline' }}
              >
                Основатель RoboInterativo| IT консультант | Devops инженер
              </Typography>

            </React.Fragment>
          }

    />
  </ListItem>
  </List>

 </Card>
 <ThemeProvider theme={theme2}>
<Box sx={{  bgcolor: 'background.paper'}}>
 <Typography variant="h3" gutterBottom>
Learning Linux Basics Course & Labs
</Typography>


{data &&  data.data.modules.map((row) => (
<div>
<Accordion sx={{  bgcolor: 'background.paper'}} >
  <AccordionSummary
    sx={{backgroundColor: 'rgba(0, 0, 0, .06)',}}
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
  >
<div sx={{ display: 'flex',
 flexDirection: 'row',
 flexWrap:  'nowrap' ,
 maxHeight: 50,

 justifyContent: 'space-between'}}>


   {row.name}
     { auth.authorize ===true ?
       <CircularProgressWithLabel value={20} />
       :
       <LockIcon />
     }
   {row.lessons_count} урока
   </div>

  </AccordionSummary>
  <AccordionDetails
      sx={{backgroundColor: 'rgba(0, 0, 0, .04)',}}
  >
  Содержание модуля: 50% Complete 2/4 Lessons
  <LessonsList auth={auth} lessons={row.lessons} />


  </AccordionDetails>
</Accordion>
<p></p>
</div>

))}

</Box>
</ThemeProvider >
</Grid>

        <Grid container size={3}>

        <Box sx={{ display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        minHeight: '200',
        maxWidth: '300',
        justifyContent: 'space-between'
       }}>
             <FormControl  component="fieldset" variant="standard">

               <FormGroup sx={{m:0.3}}>
                 <LinearProgress sx={{justifyContent: 'flex-start',}} variant="determinate" value={10} />
               </FormGroup>

               <FormGroup sx={{ m:0.3 }}>
                 <Typography  variant="h5" gutterBottom>
                Course Progress
                </Typography>
                </FormGroup>
                <FormGroup sx={{ m:0.3 }}>
                  <Typography  variant="p" gutterBottom>10% 1/10 уроков</Typography>



               </FormGroup>
              { auth.authorize ===true ?
               <div>
              <FormGroup sx={{ m:0.3 }}>
              <Button href="/courses/learning-linux-basics-course-labs/module/m001/lesson/l001" sx={{minWidth: 170  }} variant="contained" >
              Продолжить обучение
              </Button>
               </FormGroup>

              <FormGroup sx={{ m:0.3 }}>
              <Button sx={{minWidth: 170  }} variant="outlined" >
              Отписатся от курса
              </Button>
               </FormGroup>
              </div>
              :
              <FormGroup sx={{ m:0.3 }}>
              <Button href="/login" sx={{minWidth: 170  }} variant="contained" >
              Войти чтобы продолжить
              </Button>
               </FormGroup> }
             </FormControl>



             </Box>





<ThemeProvider theme={theme}>
    {data &&
     <Box sx={{ display: 'flex',
     flexDirection: 'row',
     flexWrap: 'wrap',

     minHeight: '200',
     maxWidth: '300',
     justifyContent: 'space-between'
    }}>
          <FormControl  component="fieldset" variant="standard">

            <FormGroup sx={{m:0.3}}>
           <Button size="small" sx={{minWidth: 170  }} variant="contained" disabled='true' startIcon={<MenuBookIcon />} >
           {data.data.modules_count} модулей
           </Button>
            </FormGroup>

            {(data.data.certification) &&
            <FormGroup sx={{ m:0.3 }}>
           <Button size="small" sx={{minWidth: 170  }} variant="contained" disabled='true' startIcon={<VerifiedIcon />}  >
           Сертификат
           </Button>
            </FormGroup> }
              {(data.data.all_demo_count >0) &&
            <FormGroup sx={{ m:0.3 }}>
           <Button size="small" sx={{minWidth: 170  }} variant="contained" disabled='true' startIcon={<OndemandVideoIcon />} >
           {data.data.all_demo_count} демо
           </Button>
            </FormGroup> }

            <FormGroup sx={{ m:0.3 }}>
           <Button size="small" sx={{texttransform: 'capitalize', minWidth: 170  }} variant="contained" disabled='true' startIcon={<PeopleAltIcon />} >
          сообщество
           </Button>
            </FormGroup>

            {(data.data.all_quiz_count >0) &&
            <FormGroup sx={{ m:0.3 }}>
           <Button sx={{minWidth: 170  }} variant="contained" disabled='true' startIcon={<QuizIcon />} >
             {data.data.all_quiz_count} квизов
           </Button>
            </FormGroup> }

          </FormControl>
          <FormControl
            required

            component="fieldset"

            variant="standard"
          >

            <FormGroup dense sx={{ m:0.3 }}>
               <Button size="small" sx={{minWidth: 170 ,background: 'rgb(0 0 0/4%)' }}  variant="contained" disabled='true' startIcon={<ArticleIcon />} >
                {data.data.all_lessons_count} уроков
                  </Button>
            </FormGroup>
            <FormGroup sx={{ m:0.3 }}>
               <Button size="small" sx={{minWidth: 170 ,background: 'rgb(0 0 0/4%)' }}  variant="contained" disabled='true' startIcon={<VideoLibraryIcon />} >
               {data.data.video_hours}ч.{data.data.video_mins}м.
                  </Button>
            </FormGroup>
            {(data.data.all_labs_count >0) &&
            <FormGroup sx={{ m:0.3 }}>
               <Button size="small" sx={{minWidth: 170 ,background: 'rgb(0 0 0/4%)' }}  variant="contained" disabled='true' startIcon={<LaptopMacIcon />} >
               {data.data.all_labs_count} лаб
                  </Button>
            </FormGroup> }

            <FormGroup sx={{ m:0.3 }}>
               <Button size="small" sx={{minWidth: 170 ,background: 'rgb(0 0 0/4%)' }}  variant="contained" disabled='true' startIcon={<TranslateIcon />} >
               русский
                  </Button>
            </FormGroup>

            {(data.data.all_coding_count >0) &&
            <FormGroup sx={{ m:0.3 }}>
               <Button size="small" sx={{minWidth: 170 ,background: 'rgb(0 0 0/4%)' }}  variant="contained" disabled='true' startIcon={<KeyboardIcon />} >
               {data.data.all_coding_count} code session
                  </Button>
            </FormGroup>}


          </FormControl>


        </Box>
      }
      </ThemeProvider>



</Grid>
</Grid>





)}
export default CoursesDetailed;
  //{JSON.stringify({'data':data})}
//'#1976d2'
