import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
//import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Grid2';

import React, { useState,useEffect ,useCallback}  from "react";

import { BrowserRouter as Router ,
    Routes,
    Route,
    Link,
    useNavigate,
}
 from "react-router-dom";

import Scenarios from './Scenarios';
import Courses from './Courses';
import CoursesDetailed from './CoursesDetailed';
import CourseModule from './CourseModule';
import Login from './Login';
import checkAuth from './Auth';
 // const Item = styled(Paper)(({ theme }) => ({
 //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
 //   ...theme.typography.body2,
 //   padding: theme.spacing(1),
 //   textAlign: 'center',
 //   color: theme.palette.text.secondary,
 // }));
 import axios from 'axios';



function App() {
  //const [auth,setAuth]= useState('');
      const [ auth, setAuth ] = useState('');


      const check=useCallback(()=>{
   // Do some things with props or state

        const token=localStorage.getItem("token")
        let user={}


        if (token) {
          console.log("It is not null");
          axios.get('/api/users/me', {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token
          },

          })
          .then(response => {
            if ([200].indexOf(response.status) !== -1) {
                //localStorage.setItem('auth',true);
                // setAuth(true);
                console.log(response.data);
               user= {authorize:true,userdata: response.data};
               setAuth(user);

                // localStorage.setItem("token", response.data.access_token);
                // navigate('/')
                // window.location.reload(true);

                }

          console.log("User in");

          })
         .catch(error => {
           if(error.status==401){
              // setAuth(false);
               localStorage.removeItem("token");
               user= {authorize:false,userdata:{}};
              setAuth(user);
             // navigate('/')
             //     window.location.reload(true);
           }
           console.error(error);
         });
         }
         else {
             console.log("It is null");
             // setAuth(false);
              localStorage.removeItem("token");
              user ={authorize:false,useredata:{}};
              setAuth(user);
         }
      },[])
      //console.log("USER",user);



     //
       //setAuth(user);

      // setAuth(user);


      useEffect(() => {
        check();
         }, [ ]);
       setInterval(check, 60000);
  return (

    <Router>
<Grid container spacing={2}>
<Grid size={12}>
     <Box sx={{ display: 'flex', height: 60,flexDirection: 'row' }}>

           <AppBar position="static">
            <Toolbar>
            <Typography
                       variant="p"
                       noWrap
                       component="h1"
                       href="#app-bar-with-responsive-menu"
                       sx={{
                         mr: 2,flexDirection: 'row' ,
                         display: { xs: 'none', md: 'flex' },
                         fontFamily: 'monospace',
                         fontWeight: 700,
                         letterSpacing: '.4rem',
                         color: 'inherit',
                         textDecoration: 'none',
                       }}
                     >
                       ROBO
                     </Typography>

              <Link style={{ textDecoration: 'none' }}  to="/">
               <Button sx={{ color: 'white', display: 'block' }} >
                    Домашняя
               </Button>
              </Link>
            { auth && auth.authorize ===true ?
              <Link style={{ textDecoration: 'none' }}  to="/logout">
               <Button sx={{ color: 'white', display: 'block' }} >
                    Выйти
               </Button>
              </Link>
              :
              <Link style={{ textDecoration: 'none' }}  to="/login">
               <Button sx={{ color: 'white', display: 'block' }} >
                    Войти
               </Button>
              </Link>
            }


              <Link style={{ textDecoration: 'none' }}  to="/courses">
                <Button sx={{ color: 'white', display: 'block' }} >
                  Курсы
                </Button>
              </Link>








              </Toolbar>
           </AppBar>
         </Box>
</Grid>
         </Grid>
  <Grid container spacing={2}>


         <Routes>
           <Route path="/scenarios" element={<Scenarios  />} />
         </Routes>
         <Routes>
           <Route path="/courses" element={<Courses auth={auth}  />} />
         </Routes>
         <Routes>
           <Route path="/courses/:course_path" element={<CoursesDetailed auth={auth}  />} />

         </Routes>
         <Routes>
           <Route path="/courses/:course_path/module/:modules_id/lesson/:lesson_id" element={<CourseModule  auth={auth}    />} />

         </Routes>
        <Routes>
          <Route path="/" element={<Home  />} />
        </Routes>

        <Routes>
          <Route path="/login" element={<Login  />} />
        </Routes>

          <Routes>
         <Route index element={<Home auth={auth} />} />
         </Routes>

</Grid>

    </Router>
  );
}
function Home(auth) {
  return (
    <div>
    {JSON.stringify({'rez':auth})}

    HOME</div>
  );
}
export default App;
