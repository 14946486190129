import React, { useState,useEffect }  from "react";
import axios from 'axios';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

import Iframe from 'react-iframe'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

function Courses (auth) {


  const [data,setData]= useState('');
  //http://127.0.0.1:8000/
  useEffect ( () =>  {
    axios.post('api/courses')
        .then(response => {
          console.log(response);
          setData(response)
          //localStorage.setItem('auth',response.data.auth)
        })
        .catch(error => {
           console.error('There was an error!', error);
        });

  },[])

  return (


<div>
        <Grid container  size={12}>

        <header sx={{    display: 'flex',  p: 1,
          m: 1,
      }}>
         <h1>Курсы</h1>
         </header>

       </Grid>
        <Grid container size={12}>
        {data && <p>{JSON.stringify({'rez':auth})} </p>}

         {data &&  data.data.courses.map((row) => ( <div>
           <Box
               sx={{
               display: 'flex',
              flexDirection: 'row' ,
              p: 1,
              m: 1,
              bgcolor: 'background.paper',
              borderRadius: 1,}} >
            <Card sx={{maxWidth: 300 }}>
            <CardMedia
               component="img"
               height="194"
               image="/media/media02.png"
               alt="Paella dish"
             />
            <CardHeader         title={row.title}
        subheader="Преподаватель: Шило Алексей" />



             <CardActions>
        <Button href="/courses/learning-linux-basics-course-labs" sx={{minWidth: 280 }} variant="contained"  color="primary">
          Посмотреть курс
        </Button>
      </CardActions>
            </Card>
           </Box>
           </div>))}



</Grid>
</div>





)}
export default Courses;
  //{JSON.stringify({'data':data})}
